import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { jsonPrs } from "helper/";
import { storage } from "config/storage";
import { PdfViewer, Rows, DataTable, Cols } from "components/";
import {
  DataSection,
  SectionContent,
  Title,
  Container,
  Heading,
  SubData,
  Left,
  Right,
  BalRight,
  BalanceWrapper,
  HorizontalLine,
  AmountSection,
  PDFSection,
} from "./style";
import moment from "moment";
import { isValidDate } from "helper/index";

const DetailsSection = (props) => {
  const { t } = useTranslation();
  const sessionDetails = jsonPrs(storage.getItem("sessionDetails"));
  const clientDetails = JSON.parse(storage.getItem("clientDetails")) || {};
  const clientName = clientDetails ? clientDetails.name : "";
  const { dateFormat } = sessionDetails || {};
  const {
    showDetails,
    activeTab,
    attachments,
    sectionOneTitle,
    sectionTwoTitle,
    fetchPdfStatus,
  } = props || "";
  const { sectionOneData, sectionTwoData, sectionThreeData } = props || [];
  const { balance = 0, paid = 0, total = 0, advancePaid = 0 } = sectionThreeData;

  return (
    <>
      <PDFSection showDetails={showDetails}>
        <PdfViewer
          data={attachments}
          isBlob={true}
          loading={fetchPdfStatus}
          onDownload={props.handleDownload}
        />
      </PDFSection>
      <DataSection showDetails={showDetails}>
        <Rows>
          <Title>{t(sectionOneTitle)}</Title>
          <SectionContent>
            {sectionOneData.map((data, index) => (
              <Container key={index}>
                <Heading>{t(data.label)}</Heading>
                <SubData
                  color={
                    isValidDate(data.value)
                      ? "rgb(21, 24, 27)"
                      : "rgb(145, 158, 171)"
                  }
                >
                  {data.value && data.label && isValidDate(data.value)
                    ? moment(data.value).format(dateFormat)
                    : data.value}
                </SubData>
              </Container>
            ))}
          </SectionContent>
          {sectionTwoData &&
          sectionTwoData.data &&
          sectionTwoData.data.length > 0 ? (
            <>
              <Title>{t(sectionTwoTitle)}</Title>
              <DataTable
                data={(sectionTwoData && sectionTwoData.data) || []}
                columns={(sectionTwoData && sectionTwoData.columns) || []}
              />
            </>
          ) : null}
          {sectionThreeData ? (
            <>
              <AmountSection>
                <Cols>
                  <Rows>
                    <Left>
                      {activeTab === "Payment Details" || activeTab === "Advance Payment Details"
                        ? t("msp.total")
                        : clientName.toLowerCase() === "orange" 
                          ? "" : t("msp.subTotalExclTaxes")}
                    </Left>
                    <Left>
                      {activeTab === "Payment Details" || activeTab === "Advance Payment Details"
                        ? t("msp.paidAmount")
                        : clientName.toLowerCase() === "orange" 
                        ? "" : t("msp.taxAmount")}
                    </Left>
                    {advancePaid && advancePaid !== 0
                      ? <Left>{t("msp.advancePaid")}</Left>
                      : <></>}
                  </Rows>
                  <Rows>
                    <Right>{activeTab !== "Payment Details" && activeTab !== "Advance Payment Details" && clientName.toLowerCase() === "orange" 
                            ? "" : Number(total).toFixed(2)}</Right>
                    <Right>{activeTab !== "Payment Details" && activeTab !== "Advance Payment Details" && clientName.toLowerCase() === "orange" 
                            ? "" : Number(paid).toFixed(2)}</Right>
                    {advancePaid && advancePaid !== 0 ? <Right>{Number(advancePaid).toFixed(2)}</Right> : <></>}
                  </Rows>
                </Cols>
                <Cols>
                            {activeTab !== "Payment Details" && activeTab !== "Advance Payment Details" && clientName.toLowerCase() === "orange" 
                            ? "" : <HorizontalLine />}
                </Cols>
                <Rows>
                  <Cols>
                    <BalRight>
                      {activeTab === "Payment Details" || activeTab === "Advance Payment Details"
                        ? t("msp.balance")
                        : clientName.toLowerCase() === "orange" 
                        ? "" : t("msp.total")}
                    </BalRight>
                    <BalanceWrapper>
                      <BalRight>{activeTab !== "Payment Details" && activeTab !== "Advance Payment Details" && clientName.toLowerCase() === "orange" 
                                ? "" : Number(balance).toFixed(2)} </BalRight>
                    </BalanceWrapper>
                  </Cols>
                </Rows>
              </AmountSection>
            </>
          ) : null}
        </Rows>
      </DataSection>
    </>
  );
};

DetailsSection.propTypes = {
  activeTab: PropTypes.string,
  attachments: PropTypes.any,
  sectionOneData: PropTypes.array,
  sectionOneTitle: PropTypes.any,
  sectionThreeData: PropTypes.object,
  sectionTwoData: PropTypes.array,
  sectionTwoTitle: PropTypes.any,
  showDetails: PropTypes.any,
};

export default DetailsSection;
